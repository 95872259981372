/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { type FC } from 'react';
import './FlightTicket.css';
import Button from '../Button';
import { Box, Grid2, Stack, Typography } from '@mui/material';
import Price from '../Price';
import FlightDirection from '../FlightDirection';
import CompaniesBunch from '../../features/CompaniesBunch';
import LuggageIcon from '@mui/icons-material/Luggage';
import SelfTransferIcon from '../assets/selfTransferIcon';
import { format } from 'date-fns';

const FlightTicket: FC<any> = ({ flight, totalCost = false }) => {

  const currency = (price: number, currency: string): string =>
    new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(price);
  

  return (
    <Box className="container">
      <Box className="topBlock"></Box>
      <Box className="bottomBlock"></Box>
      <Box className="content">
        <Grid2 container>
          <Grid2 size={12}>
            <FlightDirection from={flight.segments[0].origin_name} to={flight.segments[1].destination_name} />
          </Grid2>
          <Grid2 size={12} textAlign="left">
            <CompaniesBunch from={flight.segments[0].logo} to={flight.segments[1].logo}/>
          </Grid2>
          <Grid2 container size={12} textAlign="left" marginTop={3}>
            <Grid2 size={6}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#0B5D95',
                  textTransform: 'uppercase',
                }}
              >
                Departure
              </Typography>
              <Typography variant="caption" color="inherit" noWrap>
                &nbsp;
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#0B5D95',
                  textTransform: 'uppercase',
                }}
              >
                {format(flight.departure_time, 'MMM dd')}
              </Typography>
            </Grid2>
            <Grid2 size={6} textAlign="end">
              <Typography
                variant="caption"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#0B5D95',
                  textTransform: 'uppercase',
                }}
              >
                Economy
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container size={12} height="100px" alignContent="center">
            <Grid2 size={1} alignContent="flex-end">
              <Typography
                variant="caption"
                sx={{
                  fontSize: '25px',
                  fontWeight: 700,
                  color: '#05253A',
                  textTransform: 'uppercase',
                }}
              >
                {format(flight.departure_time, 'HH:mm')}
              </Typography>
            </Grid2>
            <Grid2 size={3}>
              <Box className="flightTime" alignContent="flex-end">
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#0B5D95',
                  }}
                >
                 {flight.segments[0].flight_time}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#0B5D95',
                  }}
                >
                 {format(flight.segments[0].departure_time, 'MMM dd')}
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={3} display="flex" flexDirection="column" height="100%" justifyContent="space-between">
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#0B5D95',
                }}
              >
                {flight.waiting_time}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  borderRadius: '20px',
                  background: '#98CFF0',
                }}
              >
                {`1 stop ${flight.segments[0].destination_iata}`}
              </Box>
            </Grid2>
            <Grid2 size={3}>
              <Box className="flightTime" alignContent="flex-end">
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#0B5D95',
                  }}
                >
                  {flight.segments[1].flight_time}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#0B5D95',
                  }}
                >
                  {format(flight.segments[1].departure_time, 'MMM dd')}
                </Typography>
              </Box>
            </Grid2>
            <Grid2 gap={1} size={1} alignContent="flex-end">
              <Typography
                variant="caption"
                sx={{
                  fontSize: '25px',
                  fontWeight: 700,
                  color: '#05253A',
                  textTransform: 'uppercase',
                }}
              >
                {format(flight.arrival_time, 'HH:mm')}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container size={12} justifyContent="space-between">
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#0B5D95',
              }}
            >
              {`${flight.segments[0].origin_iata} ${flight.segments[0].origin_name}`}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#0B5D95',
              }}
            >
              {`${flight.segments[1].destination_iata} ${flight.segments[1].destination_name}`}
            </Typography>
          </Grid2>
          <Grid2 container size={12} gap={2} marginTop={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <LuggageIcon
                sx={{
                  color: '#0B5D95',
                }}
              />
              <Typography color="#0B5D95" variant="body1">
              Carry-on + Checked baggage
              </Typography>
            </Stack>
            <Typography>
              <Stack direction="row" alignItems="center" gap={1}>
                <SelfTransferIcon
                  sx={{
                    color: '#0B5D95',
                  }}
                />
                <Typography color="#0B5D95" variant="body1">
                  Self-transfer
                </Typography>
              </Stack>
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
      {totalCost ? (
        <Box className="paymentContent" gap={3}>
          <Price>{currency(flight.total_price, flight.total_currency)}</Price>
          <Button onClick={() => {}}>Select</Button>
        </Box>
      ) : (
        <Box className="paymentContent" gap={3}>
          <Price>Return flight</Price>
        </Box>
      )}
    </Box>
  );
};

export default FlightTicket;
